<!--
// nuxt-ui/components/Shared/stats/CardStatsFeaturedPlayerGeneral.vue
-->
<script setup lang="ts">
import PlayerPlaceholderKL from "~/nuxt-ui/assets/images/kl-player-placeholder.png";
import PlayerPlaceholderQL from "~/nuxt-ui/assets/images/ql-player-placeholder.png";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import InfoIcon from "~/nuxt-ui/components/Shared/icons/InfoIcon.vue";
import { initDropdowns } from "flowbite";
import { setAliasUrl } from "~/src/Shared/utils";

type Props = {
  preTitleStat?: string;
  titleStat: string;
  tableStatTitle?: string;
  hasPartner?: boolean;
  partnerLogo?: string;
  partnerName?: string;
  featuredPlayerImage: string | undefined;
  featuredPlayerName: string | undefined;
  featuredPlayerTeamImage: string | undefined;
  featuredPlayerStat: number | string | null | undefined;
  pageStatsLink: string;
  isCardsStats?: boolean;
  featuredPlayerSecondStat?: number | string | null;
  teamCard?: boolean;
  disclaimer?: string;
};

const props = withDefaults(defineProps<Props>(), {
  isCardsStats: false,
  hasPartner: false,
});

const localePath = useLocalePath();
const { regionParam, isRegionHome } = useRegions();
const route = useRoute();
const { isKings } = useWebContext();
const placeholderImage = isKings ? PlayerPlaceholderKL : PlayerPlaceholderQL;

const queries = computed(() => {
  return route.query ? route.query : undefined;
});

const videoBg = ref<HTMLVideoElement | null>(null);

const playVideoBg = () => {
  if (videoBg.value && "play" in videoBg.value) {
    videoBg.value.play();
  }
};

const pauseVideoBg = () => {
  if (videoBg.value && "pause" in videoBg.value) {
    videoBg.value.pause();
  }
};

const disclaimerId = useId() || "disclaimer-dd";
const disclaimerBtnId = useId() || "disclaimer-btn-dd";

onMounted(() => {
  initDropdowns();
});
</script>

<template>
  <div class="flex flex-col max-w-[343px] w-full min-w-full lg:max-w-none 2xl:w-auto 2xl:aspect-[325/568]">
    <div class="card-player-image-container">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-${pageStatsLink}`,
            params: { region: regionParam },
            query: queries,
          })
        "
        @mouseover="playVideoBg"
        @mouseout="pauseVideoBg"
        :no-prefetch="isRegionHome ? true : undefined"
      >
        <video
          :src="isKings ? '/img/stats/bg-featured-stats-KL.webm' : '/img/stats/bg-featured-stats-QL.webm'"
          :poster="
            isKings ? '/img/stats/bg-stats-card-kl-fixed.jpg' : '/img/stats/bg-stats-card-ql-fixed.jpg'
          "
          class="absolute top-0 left-0 right-0 bottom-0 min-h-[450px] object-cover object-top"
          ref="videoBg"
          width="100%"
          height="100%"
          loop
          muted
        ></video>
        <div class="image-container">
          <NuxtImg
            v-if="teamCard"
            :src="featuredPlayerImage ? setAliasUrl(featuredPlayerImage) : ''"
            :alt="featuredPlayerName || ''"
            class="object-center object-cover my-auto"
            height="280"
            width="280"
            loading="lazy"
          />
          <NuxtImg
            v-else
            :src="featuredPlayerImage ? setAliasUrl(featuredPlayerImage) : placeholderImage"
            :alt="featuredPlayerName || ''"
            class="object-top object-cover min-h-[450px]"
            width="456"
            height="642"
            sizes="256px sm:472px md:325px"
          />
        </div>
        <div class="gradient-container">
          <div>
            <div v-if="preTitleStat" class="pre-title-stat">{{ preTitleStat }}</div>
            <div class="title-stat">{{ titleStat }}</div>
          </div>
          <div class="flex justify-end">
            <NuxtImg
              v-if="hasPartner"
              :src="setAliasUrl(partnerLogo)"
              :alt="partnerName || ''"
              class="h-5 max-h-[21px] object-contain"
              loading="lazy"
              height="21"
              width="59"
            />
          </div>
        </div>
      </nuxt-link>
      <button
        :id="disclaimerBtnId"
        v-if="disclaimer"
        class="disclaimer"
        :data-dropdown-toggle="disclaimerId"
        data-dropdown-placement="right"
      >
        <info-icon height="25" width="25"></info-icon>
      </button>
      <div
        :id="disclaimerId"
        class="z-10 hidden bg-gray-700 rounded-lg shadow w-[200px] text-sm p-2"
        :aria-labelledby="disclaimerBtnId"
      >
        {{ disclaimer }}
      </div>
    </div>

    <div class="mt-4 table-first-stats">
      <div class="flex items-center justify-end text-gray-400 text-[10px] uppercase pl-2 pr-1">
        <span class="w-4/6 text-left">{{ $t("cards.stats.name.text") }}</span>
        <span class="w-1/6 text-center ml-auto">{{ $t("cards.stats.team.text") }}</span>
        <span v-if="!isCardsStats" class="w-1/6 text-center">{{ tableStatTitle }}</span>
        <span v-if="isCardsStats" class="w-8 flex items-center justify-center">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="yellowCard">
              <path
                id="Rectangle 1724"
                d="M4.5 4.46257C4.5 3.66483 5.1467 3.01813 5.94444 3.01813H11.7222C12.52 3.01813 13.1667 3.66483 13.1667 4.46257V13.1292C13.1667 13.927 12.52 14.5737 11.7222 14.5737H5.94444C5.1467 14.5737 4.5 13.927 4.5 13.1292V4.46257Z"
                fill="#FFCF00"
              />
            </g>
          </svg>
        </span>
        <span v-if="isCardsStats" class="w-8 flex items-center justify-center">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="redCard">
              <path
                id="Rectangle 1724"
                d="M4 4.46257C4 3.66483 4.6467 3.01813 5.44444 3.01813H11.2222C12.02 3.01813 12.6667 3.66483 12.6667 4.46257V13.1292C12.6667 13.927 12.02 14.5737 11.2222 14.5737H5.44444C4.6467 14.5737 4 13.927 4 13.1292V4.46257Z"
                fill="#E62D4F"
              />
            </g>
          </svg>
        </span>
      </div>
      <div class="mt-2 flex items-center justify-end text-sm font-bold bg-gray-800 pl-2 pr-1 h-14 rounded-md">
        <div class="w-4/6 text-left">{{ featuredPlayerName }}</div>
        <div class="w-1/6 min-w-[16%] logo-team-container">
          <NuxtImg
            v-if="featuredPlayerTeamImage"
            :src="setAliasUrl(featuredPlayerTeamImage)"
            alt="Team"
            class="object-contain h-8 w-8 lg:w-11 lg:h-11"
            loading="lazy"
            height="44"
            width="44"
          />
        </div>
        <div :class="[isCardsStats ? 'w-8' : 'w-1/6', 'flex items-center justify-center']">
          {{ Number(featuredPlayerStat) }}
        </div>
        <div v-if="isCardsStats" class="w-8 flex items-center justify-center">
          {{ featuredPlayerSecondStat }}
        </div>
      </div>
    </div>
    <div class="text-center py-2" v-if="regionParam && pageStatsLink">
      <nuxt-link
        :to="
          localePath({
            name: `region-stats-${pageStatsLink}`,
            params: { region: regionParam },
            query: queries,
          })
        "
        class="see-more-link"
        :no-prefetch="isRegionHome ? true : undefined"
        >{{ $t("cards.stats.seeMore.link") }}
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped>
.card-player-image-container {
  @apply min-h-[450px] px-1 py-2 rounded-lg relative overflow-hidden bg-no-repeat bg-cover bg-center;
}

.image-container {
  @apply h-full absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-start items-center pt-5;
}

.gradient-container {
  @apply absolute min-w-full min-h-full top-0 right-0 left-0 bottom-0 bg-[linear-gradient(180deg,rgba(0,0,0,0.00)68.26%,rgba(0,0,0,0.80)100%)] px-1 py-2 flex flex-col justify-between;
}

.pre-title-stat {
  @apply text-primary-light text-center text-sm uppercase font-['Archivo_Expanded'] font-bold leading-[14px];
}

.title-stat {
  @apply text-black text-center text-2xl font-bold uppercase font-['Archivo_Expanded'] leading-none;
  -webkit-text-stroke: 1px theme("colors.primary.light");
  text-stroke: 1px theme("colors.primary.light");
}

.logo-team-container {
  @apply flex flex-col justify-center items-center ml-auto;
}

.see-more-link {
  @apply text-xs text-gray-400;
}

.disclaimer {
  @apply text-primary absolute bottom-2 left-2;
}
</style>
